import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../otherPage.css"

import DataGirl from "../images/DataGirl.png"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404｜Page Not Found" />
      <section className="Page404">
        <div className="wrap">
          <div>
            <h1>404<br></br>Page Not Found</h1>       
            <h2>お探しのページが見つかりませんでした。</h2>
          </div>
          <img src={ DataGirl } className="DataGirl"/>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
